* {
  margin: 0;
  padding: 0;
}

@font-face {
  font-family: myFirstFont;
  src: url('assets/font/Arya-Regular.ttf');
}

html {
  overflow-x: hidden;
}

body {
  margin: 0 0;
  padding: 0 0;
  font-family: myFirstFont;
}

.btn_style {
  background-color: #1f3f78;
  color: #fff3df;
  display: inline-block;
  font-size: 16px;
  line-height: 1;
  padding: 20px 32px;
  transition: 0.6s;
  border: none;
  font-weight: 600;
  text-align: center;
  border-radius: 0;
  border: none;
}


.btn_style:hover {
  color: #fff3df;
  box-shadow: #dca227 inset;
}

.btn_style_color {
  background-color: #dca227 !important;
  color: #fff3df !important;
  display: inline-block;
  font-size: 25px;
  line-height: 1;
  padding: 20px 75px;
  transition: 0.6s;
  border: none;
  font-weight: 600;
  text-align: center;
  border-radius: 0;
  border: none;
}

.btn_style_color:hover {
  color: #fff3df;
  box-shadow: 0 0 40px 40px #1f3f78 inset;
}


.view_btn_style {
  border: 2px solid #1f3f78;
  color: #fff3df;
  display: inline-block;
  font-size: 16px;
  line-height: 1;
  padding: 20px 32px;
  transition: 0.6s;
  font-weight: 600;
  text-align: center;
  border-radius: 0;
  margin-left: 15px;
}

.view_btn_style:hover {
  color: #fff3df;
  box-shadow: 0 0 40px 40px #dca227 inset;
}

.title {
  font-size: 26px;
  font-weight: 800;
  color: #1f3f78;
  margin-bottom: 16px;
}

.sub_title {
  font-size: 22px;
  font-weight: 700;
  color: #1f3f78;
  margin-bottom: 16px;
}

.para p {
  line-height: 1.7;
  text-align: justify;
}

h3 {
  font-size: 20px;
  color: #000;
  font-weight: 600;
  margin-top: 20px;
}

p {
  color: #000;
  font-size: 16px;
  font-weight: 400;
}

ul {
  padding-left: 0;
}

.common-card-body-heads h3 {
  font-size: 20px;
}

.common-card {
  width: 330px;
  background-color: #eff1f4;
  border-radius: 30px;
  margin-bottom: 35px;
  margin-top: 30px;
  overflow: hidden;
  cursor: default;
  position: relative;
  top: 0px;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  /* background: linear-gradient(1deg, #b8c5d6 0%, #e7e8ed 100%); */
}

.common-card:hover {
  -webkit-box-shadow: 0px 24px 24px 0px rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0px 24px 24px 0px rgba(0, 0, 0, 0.16);
  box-shadow: 0px 24px 24px 0px rgba(0, 0, 0, 0.16);
  animation-name: bounce;
  -moz-animation-name: bounce
}

.common-card-body-heads span {
  font-size: 15px;
}

.subMenu_content {
  width: 100%;
  margin: auto;
  padding: 20px;
}


.submit_btn {
  display: inline-flex;
  height: 40px !important;
  width: 150px !important;
  border: 2px solid #dca227;
  border-radius: 5px !important;
  color: #fff3de;
  text-transform: uppercase;
  text-decoration: none;
  font-size: 15px;
  letter-spacing: 1.5px;
  align-items: center;
  justify-content: center;
  background-color: transparent !important;
  overflow: hidden;
  position: relative;
  transition: all .35s ease-Out;
}

.submit_btn:hover {
  background-color: #BFC0C0 !important;
  color: #4e515c;
}

.common-card-body {
  padding: 0px 15px 10px 15px;
}

.imageProfile:nth-child(1) {
  position: relative;
  margin-left: -30px;
}

@keyframes bounce {

  0%,
  100% {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0)
  }

  50% {
    -webkit-transform: translateY(-10px);
    -ms-transform: translateY(-10px);
    transform: translateY(-10px)
  }
}

.banner_text_content {
  position: absolute;
  z-index: 99;
  margin: 5px 0px 0px 40px;
}

.banner_text_content {
  position: absolute;
  z-index: 99;
  margin: 130px 0px 0px 80px;
}

.banner_text_content h1 {
  font-size: 85px;
  font-weight: 900;
  color: #fff3df;
}

.banner_text_content p {
  color: #fff3df;
  font-size: 18px;
}

.sub_content {
  width: 85%;
  margin: auto;
}

/* .description {
  padding: 0px 0px 10px 15px !important
} */


.view_btn {
  display: inline-flex;
  border: 2px solid #dca227 !important;
  border-radius: 5px !important;
  background-color: transparent !important;
  color: #dca227 !important;
  text-transform: uppercase;
  text-decoration: none;
  font-size: 14px !important;
  width: 100% !important;
  letter-spacing: 1.5px;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
  transition: all .35s ease-Out;
}

.view_btn:hover {
  background-color: #dca227 !important;
  color: #fff3df !important;
}


.needMoreInfoWrap2 {
  position: relative;
  max-width: 80%;
  margin: 0 auto;
  background: #1f3f78;
  border-radius: 32px;
  overflow: hidden;
  border: 1px solid #A4B1B7;
  padding: 20px 40px;
}

.needMoreInfoWrap2 h4 {
  font-weight: 800;
  color: #fff3df;
}

.view_sm_btn {
  background-color: #dca227 !important;
  color: #fff3df !important;
  border-radius: 5px;
  background-color: transparent;
  padding: 7px;
  text-decoration: none;
  transition: all .35s ease-Out;
}

.view_sm_btn:hover {
  background-color: #1f3f78 !important;
  color: #fff3df !important;
}

.needMoreInfoWrap2 h4:before {
  position: absolute;
  content: '';
  bottom: 25px;
  /* left: 0; */
  width: 40%;
  height: 7px;
  background: #f9a91a;
  background: linear-gradient(90deg, #F9A91A 0%, #EB417A 36%, #3F91C1 67%, #FFFFFF00 100%);
}

.counsellor_btn {
  font-size: 14px;
}

.arrow_icon {
  width: 32px;
  background: #3e3b3b;
  height: 32px;
  color: #FFF;
  border-radius: 50%;
  border: 1px solid;
  font-size: 22px;
  margin: 0px 10px;
}

.arrow_icon:hover {
  background: #646060;
  cursor: pointer;
}

.readmore {
  font-size: 25px;
  padding: 0px 15px;
  cursor: pointer;
  transition: 0.6s;
}

.readmore:hover {
  color: #dca227;
}

.counsellor_btn1 {
  display: inline-flex;
  background-color: #dca227 !important;
  border-radius: 5px !important;
  color: #fffedf !important;
  text-transform: uppercase;
  text-decoration: none;
  font-size: 15px;
  width: 100% !important;
  letter-spacing: 1.5px;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
  transition: all .35s ease-Out;
}

.counsellor_btn1:hover {
  color: #dca227 !important;
  background-color: transparent !important;
  border: 2px solid #dca227 !important;
}

/* nav tab css */

.governance-structure-wrap.findYourProgram-wrap .program-tab-outer .listSearchArea .tabs-buttons.nav-tabs {
  margin-bottom: 12px;
  border: none;
  position: relative;
  white-space: nowrap;
  max-width: 1400px;
  width: 100%;
  overflow: hidden;
  overflow-x: auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
}

.findYourProgram-wrap .listSearchArea .tabs-buttons .nav-item .nav-link.active {
  color: #000;
  border: none;
}

.nav-tabs .nav-link {
  margin-bottom: calc(-1* var(--bs-nav-tabs-border-width));
  border: var(--bs-nav-tabs-border-width) solid transparent;
  border-top-left-radius: var(--bs-nav-tabs-border-radius);
  border-top-right-radius: var(--bs-nav-tabs-border-radius);
  font-weight: 600;
  border: none;
  color: #BCBCBC;
}

::-webkit-scrollbar {
  /* height: 4px; */
  width: 5px !important;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #BCBCBC;
  border-radius: 20px;
}

::-webkit-scrollbar-thumb:hover {
  background: #BCBCBC;
}

.default-btn:hover {
  color: #fff3de;
  box-shadow: 0 0 40px 40px #1f3f78 inset;
}

.default-btn {
  background-color: #dca227;
  color: #fff3de;
  display: inline-block;
  font-size: 16px;
  line-height: 1;
  padding: 20px 32px;
  transition: 0.6s;
  border: none;
  font-weight: 400;
  cursor: pointer;
  text-align: center;
}

.yellow_btn {
  background-color: #1f3f78;
  color: #FFF;
  display: inline-block;
  font-size: 16px;
  line-height: 1;
  padding: 20px 32px;
  transition: 0.6s;
  border: none;
  font-weight: 400;
  cursor: pointer;
  text-align: center;
}

.yellow_btn:hover {
  color: #fff;
  box-shadow: 0 0 40px 40px #dca227 inset;
}


table {
  width: 100%;
}

th,
td {
  border: 2px solid #dca227 !important;
  padding: 7px;
}

/* .patents_table {
  height: 500px;
  overflow-y: auto;
} */
.gallery_img img{
  object-fit: cover;
}
.pdf_btn{
  background-color: #1f3f78 !important;
  color: #FFF !important;
  margin: 20px 20px;
  transition: 0.6s;
}
.pdf_btn:hover{
  background-color: #dca227 !important;
  color: #FFF !important;
}
thead {
  background-color: #1f3f78;
  position: sticky;
  top: 0;
  z-index: 9;
  color: #fff3ed !important;
}

.subMenu_content {
  padding-top: 145px;
}


@media only screen and (max-width: 600px) {
  .default-btn {
    background-color: #dca227;
    color: #fff3de;
    display: inline-block;
    font-size: 16px;
    line-height: 1;
    padding: 18px 23px;
    transition: 0.6s;
    border: none;
    font-weight: 400;
    cursor: pointer;
    text-align: center;
}
  .subMenu_content {
    padding-top:0px !important;
}
  .banner_text_content {
    position: absolute;
    z-index: 99;
    margin: 35px 20px 0px 30px;
  }

  .needMoreInfoWrap2 {
    position: relative;
    max-width: 100%;
    margin: 0 auto;
    background: #1f3f78;
    border-radius: 32px;
    overflow: hidden;
    border: 1px solid #A4B1B7;
    padding: 20px 40px;
  }

  .title {
    font-size: 24px;
    font-weight: 800;
    color: #1f3f78;
    margin-bottom: 16px;
  }

  .banner_text_content h1 {
    font-size: 35px;
    font-weight: 900;
  }

  .btn_style {
    background-color: #dca227;
    color: #fff3df;
    display: inline-block;
    font-size: 16px;
    line-height: 1;
    padding: 12px 10px;
    transition: 0.6s;
    border: none;
    font-weight: 600;
    text-align: center;
    border-radius: 0;
    border: none;
  }

  .btn_style:hover {
    color: #fff3df;
    box-shadow: 0 0 40px 40px rgb(248, 202, 77) inset;
  }


  .view_btn_style {
    border: 2px solid #dca227;
    color: #fff3df;
    display: inline-block;
    font-size: 16px;
    line-height: 1;
    padding: 12px 10px;
    transition: 0.6s;
    font-weight: 600;
    text-align: center;
    border-radius: 0;
    margin-left: 15px;
  }

  .view_btn_style:hover {
    color: #fff3df;
    box-shadow: 0 0 40px 40px rgb(248, 202, 77) inset;
  }
}

.count_label {
  color: #1f3f78;
  font-weight: 500;
}

.line-clamp {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.info_content {
  height: 145px;
}